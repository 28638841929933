import React, { useEffect, useState, useCallback } from "react";
import { toast } from "react-hot-toast";
import { useWeb3React } from "@web3-react/core";

import useApp from "../../hooks/useApp";
import useErc20 from "../../hooks/useERC20";

import "./style.scss";

export default function Pool() {
  const { account, chainId, active } = useWeb3React();
  const { burnForEth, getStats } = useApp();
  const { balanceOf, totalSupply } = useErc20();

  const [totalSupplyValue, setTotalSupplyValue] = useState(0);

  const [info, setInfo] = useState({});

  const [inputValue, setInputValue] = useState(0);

  const [reload, setReload] = useState(Date.now());
  const [balance, setBalance] = useState(0);
  const [stats, setStats] = useState({
    totalBurned: 0,
    totalBurnRewards: 0,
  });

  const getData = async () => {
    const stats = await getStats();
    setStats({
      totalBurned: Number(stats.totalBurned.toFixed(2)),
      totalBurnRewards: Number(stats.totalBurnRewards.toFixed(2)),
    });
    if (!account) return;
    const tokenBalance = await balanceOf();
    setBalance(Number(tokenBalance.toFixed(2)));
  };

  const getInfo = async () => {
    const response = await fetch(
      "https://api.dexscreener.com/latest/dex/pairs/ethereum/0x7ec3adf26272bef82c5e216fd097e8e9de670c8e"
    );
    const info = await response.json();
    if (info) {
      setInfo(info?.pair);
    }
  };

  const onBurnClicked = async () => {
    if (!account) return;
    if (inputValue <= 0 && parseInt(inputValue) > balance) {
      toast.error("Invalid Token Value!");
      return;
    }
    try {
      await burnForEth(parseInt(inputValue));
      setReload(Date.now());
      toast.success("Burn successfully!");
    } catch (error) {
      if (error.code == 4001 || error.code == "ACTION_REJECTED")
        toast.error("User rejected the transaction");
    }
  };

  const getTotalSupply = async () => {
    let x = await totalSupply();
    setTotalSupplyValue(x);
  };

  useEffect(() => {
    getTotalSupply();
  }, []);

  useEffect(() => {
    getInfo();
  }, []);

  useEffect(() => {
    getData();
  }, [account, chainId, reload]);

  return (
    <section className="w-full py-5 flex justify-center items-center text-center">
      <div className="w-full">
        <p className="text-lg">
          0x8D49029277CffF12F7f72da7Ba070BbA720734A6
          <br />
          contact@forestfire.app
        </p>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 mt-10">
          <div className="flex flex-col gap-10">
            <div className="flex items-center justify-between py-4 rounded-full bg-gradient px-8 border-[1px] border-[#0094FF]">
              <h3 className="text-xl font-bold">Balance: </h3>
              <p className="flex text-xl gap-3">
                <span>{balance}</span>{" "}
                <span className="font-bold uppercase">$ForestFire</span>
              </p>
            </div>
            <div className="flex-1 w-full border-[1px] border-[#0094FF] bg-black/70  p-5 rounded-xl flex flex-col gap-5 lg:p-10">
              <div className="bg-white/30 hover:opacity-80 transition-all flex justify-between py-4 px-7 rounded-xl">
                <span className="text-lg font-bold">Token Name</span>
                <span className="uppercase">$ForestFire</span>
              </div>
              <div className="bg-white/30 hover:opacity-80 transition-all flex justify-between py-4 px-7 rounded-xl">
                <span className="text-lg font-bold">Token Price</span>
                <span className="uppercase">${info?.priceUsd || 0}</span>
              </div>
              <div className="bg-white/30 hover:opacity-80 transition-all flex justify-between py-4 px-7 rounded-xl">
                <span className="text-lg font-bold">Total Supply</span>
                <span className="uppercase">
                  {parseInt(totalSupplyValue)?.toLocaleString() || 0}
                </span>
              </div>
              <div className="bg-white/30 hover:opacity-80 transition-all flex justify-between py-4 px-7 rounded-xl">
                <span className="text-lg font-bold">Marketcap</span>
                <span className="uppercase">
                  $
                  {parseInt(
                    info?.priceUsd * totalSupplyValue
                  )?.toLocaleString() || 0}
                </span>
              </div>
              <div className="bg-white/30 hover:opacity-80 transition-all flex justify-between py-4 px-7 rounded-xl">
                <span className="text-lg font-bold">24h Volume</span>
                <span className="uppercase">
                  ${parseInt(info?.volume?.h24)?.toLocaleString() || 0}
                </span>
              </div>
              <div className="bg-white/30 hover:opacity-80 transition-all flex justify-between py-4 px-7 rounded-xl">
                <span className="text-lg font-bold">Liquidity</span>
                <span className="uppercase">
                  ${info?.liquidity?.usd?.toLocaleString() || 0}
                </span>
              </div>
            </div>
          </div>
          <div className="border-[1px] border-[#0094FF] bg-black/70 p-5 rounded-xl lg:p-8">
            <h3 className="text-3xl font-bold mb-8">Burn Tokens For ETH</h3>
            <div className="grid grid-cols-1 gap-8 px-0 lg:px-10">
              <div className="flex flex-col justify-between py-7 bg-gradient-2 rounded-xl px-10 text-xl">
                <h3 className="border-b-[1px] pb-3 mb-5">Total Burnt</h3>
                <p className="flex justify-between">
                  <div>
                    <span>{stats.totalBurned?.toLocaleString() ?? 0}</span>{" "}
                    {stats.totalBurned ? (
                      <span>
                        ({((stats.totalBurned * 100) / 100000000).toFixed(2)}
                        %)
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <span className="font-bold uppercase">$ForestFire</span>
                </p>
              </div>
              <div className="flex text-xl flex-col justify-between py-7 bg-gradient-2 rounded-xl px-10">
                <h3 className="border-b-[1px] pb-3 mb-5">
                  Total Burnt Rewards
                </h3>
                <p className="flex justify-between">
                  <span>{stats.totalBurnRewards ?? 0}</span>{" "}
                  <span className="font-bold">ETH</span>
                </p>
              </div>
            </div>

            <div className="flex justify-center gap-5 mt-10">
              <input
                className="px-3 py-2 bg-black/30 border-[1px] border-[#FF7A00] text-white min-w-[220px] text-xl lg:min-w-[300px] rounded-md"
                placeholder="Amount of tokens...."
                value={inputValue}
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
                type="number"
              />
              <button
                className="bg-white text-black px-4 rounded-md"
                onClick={() => {
                  if (balance) {
                    setInputValue(balance);
                  }
                }}
              >
                MAX
              </button>
            </div>
            <span className="block mt-4">
              The minimum burnt amount must be lower than 0.1 ETH
            </span>
            <button
              className="mt-8 btn-primary py-3 text-xl font-semibold uppercase rounded-md px-5 text-white transition-all hover:opacity-80 hover:scale-105"
              onClick={onBurnClicked}
            >
              Burn To ETH
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
